<template>
    <div class="classify-two-all" ref="scroll" @scroll="handleScroll($event)">
        <IndexHeader :title="title">
            <span slot="right" class="top-right" @click="showFilters = true">Filters</span>
        </IndexHeader>
        <van-tabs swipeable v-model="activeName" class="tab-box" sticky offset-top="10.66667vw" :ellipsis="false" title-active-color="#ee0a24">
            <van-tab v-for="(item, index) in TwoTypeList" :title="item.title_en" :key="index" :name="item.id">
                <GoodsColumns style="margin-top:3vw" @cartClick="handleCart" :data="goodsList" />
            </van-tab>
        </van-tabs>
        <p class="search-no" v-if="noMore">No More Products</p>
        <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

        <!-- 选择SKU 加入购物车 -->
        <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

        <!-- 加入购物车动画 -->
        <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
        <transition name="van-fade">
            <cart-flex v-show="start" />
        </transition>


        <van-popup v-model="showFilters" position="bottom" class="fliters-box">
            <IndexHeader title="Fliters" @close="showFilters = false" :isBack="false">
                <span slot="right" class="top-right" @click="resetFilters">Clear</span>
            </IndexHeader>
            <h3>Fliters By Price</h3>
            <div class="price-box">
                <van-slider v-model="priceValue" range  @change="onChange" min="0" max="999" />
                <div class="price-area">
                    <span>${{priceValue[0].toFixed(2)}}</span>
                    <span>${{priceValue[1].toFixed(2)}}</span>
                </div>
            </div>
            <h3>Sort</h3>
            <div class="sort-box">
                <div class="box-item" v-for="(item, index) in sortList" :key="index" :class="{'activeSort' : sortActive == index}" @click="sortActive = index">
                    {{item.name}}
                </div>
            </div>
            <h3>Discount</h3>
            <div class="promo-box">
                <div class="box-item" v-for="(item, index) in promoList" :key="index" :class="{'activePromo' : promoActive == index}"
                @click="promoActive = promoActive == index ? -1 : index">
                    {{100 - item.off}}% OFF
                </div>
            </div>
            <div class="apply-set" @click="reGetGoods">APPLY</div>
        </van-popup>
    </div>
</template>

<style scoped lang="less">
.classify-two-all{height:100%;overflow-y: auto;
    .en-m-header{background-color: #fff !important;}
    /deep/.tab-box{width:100%;padding-top:10.66667vw;box-sizing: border-box;
        .van-tabs__wrap{position: relative;
            &::after{content: '';width:100%;height:1px;display: block;bottom:1vw;background-color: #e1e1e1;left:0;position: absolute;}
        }
        .van-tabs__nav{padding-bottom:3vw}
        .van-tabs__line{height:0.55vw}
    }
    .fliters-box{padding-top:12.8vw;width: 100%;height: 500px;
        >h3{
            font-size: 4.26667vw;
            
            font-weight: 500;
            color: #000000;
            padding:0 4.26667vw;
        }
        .price-box{width:100%;margin-bottom:5.33333vw;padding:0 8vw;margin-top:5.33333vw;
            .price-area{display: flex;justify-content: space-between;width:calc(100% + 5.33333vw);margin-left:-2.66667vw;margin-top:4vw;
                span{
                    display: block;
                    font-size: 3.73333vw;
                    
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
        .sort-box{width:100%;margin-bottom:5.33333vw;padding:0 2.66667vw}
        .promo-box{width:100%;margin-bottom:5.33333vw;padding:0 2.66667vw}
        .box-item{display: inline-block;padding:5px 8px;border:1px solid #e1e1e1;border-radius: 1.06667vw;margin:1.33333vw;   
            font-size: 3.73333vw;
            
            font-weight: 500;
            color: #000000;
        }
        .activePromo,.activeSort{background-color: #0854BF;border-color: #0854BF;color:#fff}
        .apply-set{position: fixed;bottom:0;left:0;width:100%;height:13.33333vw;border-radius: 2.13333vw 2.13333vw 0 0;box-shadow: 0 -0.8vw 0.8vw #f1f1f1;
        text-align: center;line-height: 13.33333vw;font-size: 4.26667vw;font-weight: 500;color: #0854BF;}
    }
}
.top-right{color:#2A65BA !important;font-size:3.2vw;font-weight:600}
.search-no{line-height:50px;font-size:12px;color:#888;text-align:center}
</style>

<script>
import IndexHeader from '@/components/en/m-header.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'
import { getTwoTypeList, getTypeGoodsList, getFilterList } from '@/api/en/classify.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
    mixins: [ MixinScroll ],
    components: { IndexHeader, GoodsColumns, MSku, MAnimate, CartFlex },
    data() {
        return {
            activeName : '',
            skuModal   : false,    // 控制显示 选择sku加入购物车面板
            loading    : false,
            islock     : false,     // 防止多次加载
            start      : false,      // 是否开启加入购物车动画
            start_dom  : null,   // 开启动画的起点DOM
            showFilters: false, // 打开筛选
            priceValue : [0, 999], // 价格筛选
            sortList   : [
                {name  : 'Comprehensive'},
                {name  : 'Latest Arrival'},
                {name  : 'Rating'},
                {name  : 'Price: Low to high'},
                {name  : 'Price: high to low'}
            ],
            promoList  : [],
            sortActive : 0,
            promoActive: -1,
            TwoTypeList: [],
            goodsList  : [],
            page       : 1,
            noMore     : false,
            goodsId    : '',
            title      : ''
        }
    },
    methods: {
        resetFilters() {
            // 重置筛选条件
            this.priceValue  = [0, 999]
            this.sortActive  = 0
            this.promoActive = -1
        },
        onChange(value) {
            this.$toast('当前值：' + value);
        },
        // 内容滑动事件
        handleScroll(e){
            e.preventDefault()
            e.stopPropagation()
            if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
                if(!this.islock && !this.noMore){
                    this.page++
                    this.getTypeGoodsListHandle()
                }
            }
        },
        // 商品列表购物车点击事件
        handleCart(goods,e){
            this.goodsId = goods
            this.skuModal  = true
            this.start_dom = e
        },
        // sku 弹窗加入购物车成功回调
        handleCartSuccess(){
            this.skuModal = false
            let timer = setTimeout( () => {
                this.start = true
                clearTimeout(timer)
            }, 300)
        },
        // 加入购物车动画结束
        handleCartEnd(){
            this.start = false
        },
        getFilterListHandle() {
            getFilterList().then(res => {
                if(res) {
                    this.promoList = res.data
                }
            })
        },
        getTwoTypeListHandle(id, isFirst = false) {
            getTwoTypeList(id).then(res => {
                if(res) {
                    this.TwoTypeList = res.data
                    if(isFirst && res.data.length > 0) {
                        this.activeName = res.data[0].id
                    }
                }
            })
        },
        getTypeGoodsListHandle() {
            this.islock  = true
            this.loading = true
            let params = {
                type_id: this.activeName,
                sort: this.sortActive - 0 + 1,
                page: this.page,
                min_price: this.priceValue[0] == 0   ? null : this.priceValue[0],
                max_price: this.priceValue[1] == 999 ? null : this.priceValue[1],
                off: this.promoActive == -1 ? null : this.promoList[this.promoActive].off
            }
            getTypeGoodsList(params).then(res => {
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                    if(res.data.data.length < res.data.per_page) {
                        this.noMore = true
                    }
                } else {
                    this.noMore = true
                }
            }).finally(() => {
                this.loading = false
                this.islock  = false
            })
        },
        reGetGoods() {
            // 重新获取数据
            this.page        = 1
            this.noMore      = false
            this.goodsList   = []
            this.showFilters = false
            this.getTypeGoodsListHandle()
        }
    },
    activated() {
        if(this.title != this.$route.query.title) {
            this.title = this.$route.query.title
            this.goodsList = []
            this.page = 1
            this.getTwoTypeListHandle(this.$route.query.id, true)
            this.getFilterListHandle()
        }
    },
    created() {
        this.title = this.$route.query.title
        this.getTwoTypeListHandle(this.$route.query.id, true)
        this.getFilterListHandle()
    },
    watch: {
        activeName(val) {
            if(val) {
                this.page      = 1
                this.noMore    = false
                this.goodsList = []
                this.getTypeGoodsListHandle()
            }
        }
    }
}
</script>